/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Spinner = ({ className, text }) => (
  <div className={cn('spinner', className)} title={text}>
    <div className="spinner__icon"></div>
  </div>
);

Spinner.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

Spinner.propTypesMeta = 'exclude';

export default Spinner;
