import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useClickOutside from '../../hooks/use-click-outside';

const DropDown = ({ items, label, onOptionClicked, selectedOptionIndex }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const handleOptionClick = index => {
    setIsOpen(false);
    onOptionClicked(index);
  };
  const close = () => setIsOpen(false);
  const dropdownRef = React.useRef();
  useClickOutside(dropdownRef, close);

  return (
    <div className="drop-down" ref={dropdownRef}>
      <button
        className="drop-down__header drop-down__header_with_arrow"
        onClick={toggling}
        aria-pressed={isOpen}
      >
        {items[selectedOptionIndex].heading}
      </button>
      <span className="drop-down__label">{label}</span>

      <ul
        className={cn('drop-down__options', {
          'drop-down__options_opened': isOpen,
        })}
        aria-expanded={isOpen}
      >
        {items.map((item, index) => (
          <li key={index} className="drop-down__option">
            <button
              onClick={() => handleOptionClick(index)}
              className="drop-down__option-button"
            >
              {item.heading}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

DropDown.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  onOptionClicked: PropTypes.func,
  selectedOptionIndex: PropTypes.number,
};

DropDown.propTypesMeta = 'exclude';

export default DropDown;
