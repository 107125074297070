import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const SelectOption = ({ id, value, label, isSelected, onChange }) => {
  const handleChange = () => {
    onChange({ value: value });
  };

  return (
    <div className="select-option">
      <button
        id={id}
        type="button"
        className={cn('select-option__label', {
          'select-option__label--checked': isSelected,
        })}
        onClick={handleChange}
      >
        {label}
      </button>
    </div>
  );
};

SelectOption.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
};

SelectOption.propTypesMeta = {
  inputName: 'exclude',
  id: 'exclude',
};

export default SelectOption;
