import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const ariaCurrentTypes = {
  page: 'page',
  step: 'step',
  location: 'location',
  true: 'true',
};

const Link = ({
  ariaCurrent,
  className,
  openInNewTab = false,
  url,
  text,
  tabindex = null,
  ariaLabelledBy,
  ariaDescribedBy,
}) => (
  <a
    href={url}
    className={cn('link', {
      [className]: className,
    })}
    target={openInNewTab ? '_blank' : null}
    tabIndex={tabindex}
    aria-current={ariaCurrent ? ariaCurrent : null}
    aria-labelledby={ariaLabelledBy ? ariaLabelledBy : null}
    aria-describedby={ariaDescribedBy ? ariaDescribedBy : null}
  >
    {text}
  </a>
);

Link.propTypes = {
  className: PropTypes.string,
  openInNewTab: PropTypes.bool,
  url: PropTypes.string,
  text: PropTypes.string,
  tabindex: PropTypes.number,
  ariaCurrent: PropTypes.oneOf(Object.values(ariaCurrentTypes)),
  ariaLabelledBy: PropTypes.string,
  ariaDescribedBy: PropTypes.string,
};

Link.propTypesMeta = {
  className: 'exclude',
  tabindex: 'exclude',
  ariaCurrent: 'exclude',
  ariaLabelledBy: 'exclude',
  ariaDescribedBy: 'exclude',
};

Link.ariaCurrentTypes = ariaCurrentTypes;

export default Link;
