import React from 'react';
import PropTypes from 'prop-types';
import Answer from './answer';
import RichText from 'components/rich-text';

const Question = ({
  textHtml,
  answers,
  showResults,
  questionNumber,
  selectNoteText,
  onChange = () => {},
  isSelected,
  showValidation,
}) => {
  const [checkedValue, setIsChecked] = React.useState(null);
  const [checkedItems, setCheckedItems] = React.useState({});
  const handleChange = event => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
    setIsChecked(null);
  };

  const isMultipleChoice = answers.filter(a => a.isCorrect).length > 1;

  React.useEffect(() => {
    const allMultipleChoicesFalse = arr => arr.every(v => v === false);

    let checkIfMultySelected =
      Object.keys(checkedItems).length > 0
        ? !allMultipleChoicesFalse(Object.values(checkedItems))
        : false;
    let checkIfSingleSelected = isMultipleChoice
      ? false
      : checkedValue !== null;

    onChange(questionNumber, checkIfMultySelected || checkIfSingleSelected);
  }, [checkedValue, checkedItems]);

  return (
    <div>
      <RichText text={textHtml} />
      {showValidation && !isSelected && (
        <div className="questionnaire__question-validation-text">
          {selectNoteText}
        </div>
      )}
      <ul className="questionnaire__answers">
        {answers.map((answer, index) => (
          <li key={index}>
            <Answer
              isMultipleChoice={isMultipleChoice}
              showResults={showResults}
              name={`question-${questionNumber}answer-${index}`}
              checked={
                isMultipleChoice
                  ? checkedItems[`question-${questionNumber}answer-${index}`]
                  : checkedValue === `question-${questionNumber}answer-${index}`
              }
              onChange={
                isMultipleChoice
                  ? handleChange
                  : () =>
                      setIsChecked(`question-${questionNumber}answer-${index}`)
              }
              {...answer}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

Question.propTypes = {
  textHtml: PropTypes.string.isRequired,
  showResults: PropTypes.bool,
  questionNumber: PropTypes.number,
  selectNoteText: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.exact(Answer.propTypes)).isRequired,
  onChange: PropTypes.func,
  isSelected: PropTypes.bool,
  showValidation: PropTypes.bool,
};

Question.propTypesMeta = {
  questionNumber: 'exclude',
  showResults: 'exclude',
  selectNoteText: 'exclude',
  onChange: 'exclude',
  isSelected: 'exclude',
  showValidation: 'exclude',
};

export default Question;
