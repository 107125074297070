import React from 'react';
import PropTypes from 'prop-types';
import ImageWithCaption from 'components/image-with-caption';
import Slider from 'react-slick';
import debounce from 'lodash/debounce';
import cn from 'classnames';

const gallerySettings = {
  dots: true,
  centerMode: true,
  infinite: false,
  speed: 500,
  centerPadding: 0,
  slidesToScroll: 1,
  slidesToShow: 1,
};

const galleryImageClassName = 'image-gallery__image';

const ImageGallery = ({ items, className }) => {
  const imageGalleryContainer = React.useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);

  const getSlideHeight = slideIndex => {
    let slideHeight = 0;
    if (imageGalleryContainer && imageGalleryContainer.current) {
      const currentSlide = imageGalleryContainer.current.querySelectorAll(
        '.' + galleryImageClassName
      )[slideIndex];
      const currentSlideImage = currentSlide.querySelector('.image');
      if (currentSlideImage) {
        slideHeight = currentSlideImage.offsetHeight;
      }
    }
    return slideHeight;
  };

  const updateNavigationControlsPosition = currentSlideIndex => {
    if (imageGalleryContainer && imageGalleryContainer.current) {
      const slideHeight = getSlideHeight(currentSlideIndex);
      const sliderDots = imageGalleryContainer.current.querySelector(
        '.slick-dots'
      );
      const sliderArrows = imageGalleryContainer.current.querySelectorAll(
        '.slick-arrow'
      );
      if (sliderArrows && sliderArrows.length > 0) {
        for (let i = 0; i < sliderArrows.length; i++) {
          sliderArrows[i].style.top = slideHeight / 2 + 'px';
          sliderArrows[i].style.display = 'block';
        }
      }
      if (sliderDots) {
        sliderDots.style.top = slideHeight - 65 + 'px';
        sliderDots.style.display = 'block';
      }
    }
  };

  React.useEffect(() => {
    function recalculateElementsPosition() {
      updateNavigationControlsPosition(currentSlideIndex);
    }
    const debouncedRecalculateElementsPosition = debounce(
      recalculateElementsPosition,
      100
    );
    window.addEventListener('resize', debouncedRecalculateElementsPosition);
    return () => {
      window.removeEventListener(
        'resize',
        debouncedRecalculateElementsPosition
      );
    };
  }, []);

  React.useEffect(() => {
    updateNavigationControlsPosition(currentSlideIndex);
  }, [currentSlideIndex]);

  const onSlideChange = currentSlideIndex => {
    setCurrentSlideIndex(currentSlideIndex);
  };

  return items && items.length > 0 ? (
    <div
      ref={imageGalleryContainer}
      className={cn('image-gallery', {
        [className]: className,
      })}
    >
      <div className="image-gallery__container">
        <Slider {...gallerySettings} afterChange={onSlideChange}>
          {items.map((image, index) => (
            <React.Fragment key={index}>
              <ImageWithCaption
                {...image}
                className={galleryImageClassName}
                imageSizeClassName="image_size_image-gallery"
              />
            </React.Fragment>
          ))}
        </Slider>
      </div>
    </div>
  ) : null;
};

ImageGallery.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(ImageWithCaption.propTypes))
    .isRequired,
};

ImageGallery.propTypesMeta = {
  className: 'exclude',
};

export default ImageGallery;
