import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';
import Icon from 'components/icon';
import cn from 'classnames';

const themes = {
  blueWithIcon: '--blue-with-icon',
};

const QuickSearchPanel = ({
  searchInputLabel,
  placeholder,
  searchPageUrl,
  searchInputName,
  helpTextHtml,
  submitButtonText,
  formAriaLabel = 'Søkefelt for globalsøk i hurtigsøkpanelet',
  autocompleteEndpoint, // TODO: BE side done, FE side is not implemented yet.
  theme,
}) => {
  const inputRef = React.useRef();

  React.useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <div className={cn('quick-search-panel', theme)}>
      <form
        className="quick-search-panel__form"
        action={searchPageUrl}
        method="GET"
        role="search"
        aria-label={formAriaLabel}
      >
        <div className="quick-search-panel__container">
          <input
            className="quick-search-panel__input"
            type="text"
            name={searchInputName}
            placeholder={placeholder}
            ref={inputRef}
            aria-label={searchInputLabel}
          />
          {theme === themes.blueWithIcon && (
            <button
              className="quick-search-panel__icon-submit-button"
              aria-label={submitButtonText}
            >
              <Icon className="quick-search-panel__search-icon" name="search" />
            </button>
          )}
        </div>
        {helpTextHtml && (
          <RichText
            className="quick-search-panel__explanation rich-text_in-quick-search-panel"
            text={helpTextHtml}
          />
        )}
        {theme !== themes.blueWithIcon && (
          <button className="quick-search-panel__submit">
            {submitButtonText}
          </button>
        )}
      </form>
    </div>
  );
};

QuickSearchPanel.propTypes = {
  searchInputLabel: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  searchPageUrl: PropTypes.string.isRequired,
  searchInputName: PropTypes.string.isRequired,
  helpTextHtml: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  autocompleteEndpoint: PropTypes.string,
  formAriaLabel: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
};

QuickSearchPanel.propTypesMeta = {
  theme: 'exclude',
};

QuickSearchPanel.themes = themes;

export default QuickSearchPanel;
