import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/icon/icon';

const themes = {
  subscribe: 'subscribe',
};

const LinkButton = ({
  url,
  text,
  openInNewTab = false,
  className,
  iconName,
  theme,
}) => {
  return (
    <a
      className={cn('link-button', className, {
        'link-button_theme_subscribe': theme === 'subscribe',
      })}
      href={url}
      target={openInNewTab ? '_blank' : null}
    >
      {text}
      {iconName && <Icon name={iconName} />}
    </a>
  );
};

LinkButton.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(themes)),
};

LinkButton.propTypesMeta = {
  className: 'exclude',
  iconName: 'exclude',
  theme: 'exclude',
};

export default LinkButton;
