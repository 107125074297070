import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const RichText = ({ text, className, id }) => {
  return !text ? null : (
    <div
      id={id}
      className={cn('rich-text', className)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

RichText.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

RichText.propTypesMeta = {
  id: 'exclude',
  className: 'exclude',
};

export default RichText;
