import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const CoursePartLink = ({
  text,
  partId,
  onClick = () => {},
  active = false,
}) => {
  return (
    <button
      onClick={() => onClick({ partId })}
      className={cn('course-content__navigation-item-button', {
        'course-content__navigation-item-button_active': active,
      })}
      disabled={active}
      aria-current={active ? 'true' : null}
    >
      <span className="course-content__navigation-item-button-text">
        {text}
      </span>
    </button>
  );
};

CoursePartLink.propTypes = {
  text: PropTypes.string.isRequired,
  partId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

CoursePartLink.propTypesMeta = {
  onClick: 'exclude',
  active: 'exclude',
};

export default CoursePartLink;
