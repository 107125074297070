import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';
import Factbox from 'components/factbox';
import cn from 'classnames';

const FactboxWithTextBlock = ({
  factbox,
  className,
  factboxPosition,
  textHtml,
}) => {
  const twoColumns = factbox && textHtml;

  return factbox || textHtml ? (
    <div
      className={cn('factbox-with-text-block', {
        [className]: className,
      })}
    >
      <div
        className={cn('factbox-with-text-block__container', {
          'factbox-with-text-block__container_reversed':
            factboxPosition === 'right',
          'factbox-with-text-block__container_aligned': !twoColumns,
        })}
      >
        {factbox && (
          <div className="factbox-with-text-block__column">
            <Factbox {...factbox} />
          </div>
        )}
        {textHtml && (
          <div className="factbox-with-text-block__column">
            <RichText
              className="factbox-with-text-block__text rich-text_in-factbox-with-text-block"
              text={textHtml}
            />
          </div>
        )}
      </div>
    </div>
  ) : null;
};

FactboxWithTextBlock.propTypes = {
  className: PropTypes.string,
  factbox: PropTypes.exact(Factbox.propTypes),
  factboxPosition: PropTypes.oneOf(['left', 'right']).isRequired,
  textHtml: PropTypes.string,
};

FactboxWithTextBlock.propTypesMeta = {
  className: 'exclude',
};

export default FactboxWithTextBlock;
