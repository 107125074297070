import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';
import ImageWithCaption from 'components/image-with-caption';
import cn from 'classnames';

const ImageWithTextBlock = ({ image, className, imagePosition, textHtml }) => {
  const twoColumns = image && textHtml;

  return image || textHtml ? (
    <div
      className={cn('image-with-text-block', {
        [className]: className,
      })}
    >
      <div
        className={cn('image-with-text-block__container', {
          'image-with-text-block__container_reversed':
            imagePosition === 'right',
          'image-with-text-block__container_aligned': !twoColumns,
        })}
      >
        {image && (
          <div className="image-with-text-block__column">
            <ImageWithCaption
              {...image}
              imageSizeClassName="image_size_image-with-text-block"
            />
          </div>
        )}
        {textHtml && (
          <div className="image-with-text-block__column">
            <RichText
              className="image-with-text-block__text rich-text_in-image-with-text-block"
              text={textHtml}
            />
          </div>
        )}
      </div>
    </div>
  ) : null;
};

ImageWithTextBlock.propTypes = {
  className: PropTypes.string,
  image: PropTypes.exact(ImageWithCaption.propTypes),
  imagePosition: PropTypes.oneOf(['left', 'right']).isRequired,
  textHtml: PropTypes.string,
};

ImageWithTextBlock.propTypesMeta = {
  className: 'exclude',
};

export default ImageWithTextBlock;
