import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import cn from 'classnames';

const icons = {
  instagram: 'instagram',
  facebook: 'facebook',
  youtube: 'youtube',
};

const SocialLink = ({ url, icon, className, openInNewTab, ariaLabel }) => {
  return (
    <a
      className={cn('social-link__link', className)}
      href={url}
      target={openInNewTab ? '_blank' : null}
      aria-label={ariaLabel}
    >
      <Icon useFill className="social-link__icon" name={icon} />
    </a>
  );
};

SocialLink.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(Object.values(icons)).isRequired,
  openInNewTab: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

SocialLink.propTypesMeta = {
  className: 'exclude',
};

export default SocialLink;
