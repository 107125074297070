import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import RichText from 'components/rich-text';
import Link from 'components/link';

const PagesListItem = ({ image, heading, introHtml, link }) =>
  (() => {
    const linkUrlExists = link && link.url;
    const ItemContent = () => (
      <React.Fragment>
        <div className="pages-list-item__row">
          {image && <Image className="pages-list-item__image" {...image} />}
        </div>
        <div className="pages-list-item__row">
          {heading && <h2 className="pages-list-item__heading">{heading}</h2>}
          {introHtml && (
            <RichText
              className="rich-text_in-pages-list-item"
              text={introHtml}
            />
          )}
          {linkUrlExists && link.text && (
            <span className="pages-list-item__link link_theme_getting-started">
              {link.text}
            </span>
          )}
        </div>
      </React.Fragment>
    );
    return linkUrlExists ? (
      <a
        className="pages-list-item pages-list-item__link-holder"
        href={link.url}
        target={link.openInNewTab ? '_blank' : null}
        tabIndex={link.tabindex}
        aria-current={link.ariaCurrent ? link.ariaCurrent : null}
      >
        <ItemContent />
      </a>
    ) : (
      <div className="pages-list-item">
        <ItemContent />
      </div>
    );
  })();

PagesListItem.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  id: PropTypes.number.isRequired,
};

export default PagesListItem;
