import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';
import DropDown from 'components/drop-down/drop-down';
import IngredientBase from './ingredient-base';

const Ingredients = ({
  heading,
  forAdults,
  forChildren,
  forKindergarten,
  label,
}) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const items = [forAdults, forChildren, forKindergarten].filter(Boolean);
  const selectedItem = items[selectedOptionIndex] || null;
  return (
    <div className="ingredients">
      {heading && <h2 className="ingredients__heading">{heading}</h2>}
      <DropDown
        label={label}
        items={items}
        selectedOptionIndex={selectedOptionIndex}
        onOptionClicked={setSelectedOptionIndex}
      />
      {selectedItem && (
        <RichText
          className="rich-text_in-ingredients"
          text={selectedItem.text}
        />
      )}
    </div>
  );
};

Ingredients.propTypes = {
  heading: PropTypes.string,
  label: PropTypes.string,
  forAdults: PropTypes.exact(IngredientBase.propTypes),
  forChildren: PropTypes.exact(IngredientBase.propTypes),
  forKindergarten: PropTypes.exact(IngredientBase.propTypes),
};

Ingredients.propTypesMeta = {
  className: 'exclude',
};

export default Ingredients;
