import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';
import Image from 'components/image';
import cn from 'classnames';

const Factbox = ({ className, backgroundColor, title, icon, textHtml }) => {
  return (
    <div
      className={cn('factbox', {
        [className]: className,
      })}
      style={{ backgroundColor }}
    >
      {icon && (
        <div className="factbox__icon-holder">
          <Image className="image_size_factbox factbox__icon" {...icon} />
        </div>
      )}
      {title && <h2 className="factbox__title">{title}</h2>}
      {textHtml && (
        <RichText
          className="factbox__text rich-text_in-factbox"
          text={textHtml}
        />
      )}
    </div>
  );
};

Factbox.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  icon: PropTypes.exact(Image.propTypes),
  title: PropTypes.string,
  textHtml: PropTypes.string,
};

Factbox.propTypesMeta = {
  className: 'exclude',
};

export default Factbox;
